export const USER_TYPES = {
  SET_CURRENT_USER: "user/SET_CURRENT_USER",
  CHECK_USER_SESSION: "user/CHECK_USER_SESSION",
  EMAIL_SIGN_IN_START: "user/EMAIL_SIGN_IN_START",
  GOOGLE_SIGN_IN_START: "user/GOOGLE_SIGN_IN_START",
  SIGN_IN_SUCCESS: "user/SIGN_IN_SUCCESS",
  SIGN_IN_FAIL: "user/SIGN_IN_FAIL",
  SIGN_OUT: "user/SIGN_OUT",
  SIGN_OUT_SUCCESS: "user/SIGN_OUT_SUCCESS",
  SIGN_OUT_FAIL: "user/SIGN_OUT_FAIL",
  SIGN_UP_START: "user/SIGN_UP_START",
  SIGN_UP_SUCCESS: "user/SIGN_UP_SUCCESS",
  SIGN_UP_FAIL: "user/SIGN_UP_FAIL",
  TOGGLE_SIGNIN_SIGNUP: "user/TOGGLE_SIGNIN_SIGNUP",
};
